body {
  overflow-x: hidden;
}

.tit h2 {
  color: #a6221d;
  margin-bottom: 30px;
  font-weight: 600;
}

.row.top-txt a {
  text-decoration: none;
  color: #5c5b5b;
  margin: 0 auto !important;
}

.abt1-steel {
  p {
    text-align: justify;
    font-size: 16px;
  }

  a {
    text-decoration: none;
    font-size: 22px;
    padding: 10px;
    border-radius: 30px;
    color: #fff;
  }
}

.tit {
  padding: 20px 0 20px;
  margin-top: 30px;
}

.abt1-a {
  padding: 10px;
  background: #5c5b5b;
  color: #fff;
}

a.abt1-a:hover {
  color: #fff;
}

.abt1-steel {
  a:hover {
    color: #000;
  }

  // background: url("../img/abtsec.png");
  background: url("../../../../img/abtsec.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 25rem;
  position: relative;
}

.some img {
  position: absolute;
  width: 23%;
  top: 60px;
  right: 433px;
}
.foot-cont address {
  display: flex;
  align-items: stretch;
}
.foot-cont address svg{
  font-size: 20px;
}
.foot-cont svg, .cont-footer1 svg {
  color: #B50100;
  font-weight: 900;
}
/* button hover */
/* Btn 12 */

.button12 {
  cursor: pointer;
  background-color: #000;
  width: 330px;
  height: 64px;
  padding: 20px 50px;
  line-height: 64px;
  position: relative;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 0;
  background: #a71e25;
  margin-left: 20px;

  span {
    color: #fff;
    display: inline-block;
    padding-left: 35px;
    text-transform: uppercase;
    font: bold 18px/66px Arial;
    transform: scaleX(0.6);
    letter-spacing: 3px;
    transform-origin: center left;
    transition: color 0.3s ease;
    position: relative;
    z-index: 1;
  }

  em {
    position: absolute;
    height: 1px;
    background: #fff;
    width: 47%;
    right: 23px;
    top: 50%;
    transform: scaleX(0.25);
    -webkit-transform: scaleX(0.25);
    transform-origin: center right;
    transition: all 0.3s ease;
    z-index: 1;
  }

  &:before,
  &:after {
    content: "";
    background: #fff;
    height: 50%;
    width: 0;
    position: absolute;
    transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  &:before {
    top: 0;
    left: 0;
    right: auto;
  }

  &:after {
    bottom: 0;
    right: 0;
    left: auto;
  }

  &:hover {
    &:before {
      width: 100%;
      right: 0;
      left: auto;
    }

    &:after {
      width: 100%;
      left: 0;
      right: auto;
    }

    span {
      color: #000;
    }

    em {
      background: #000;
      transform: scaleX(0.51);
      transform: scaleX(0.51);
    }
  }
}

/* end of button hover */

@media only screen and (max-width: 1024px) {
  .some img {
    position: absolute;
    width: 25%;
    top: 97px;
    right: 313px;
  }

  .silder2 {
    height: 25rem;
  }
}

@media only screen and (max-width: 768px) {
  .abt1-steel {
    background: none;
    position: relative;
  }

  .some {
    background: url("../../../../img/img3.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 25rem;

    img {
      display: none;
    }
  }

  .pro-sli {
    position: absolute;
    top: 96px;
    right: 40px;
  }

  .cout-up {
    padding: 20px 0 20px;
  }

  .count-up h2 {
    font-size: 30px;
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .some {
    background: none;
  }

  .abt1-steel {
    text-align: center;

    a {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 425px) {
  .abt1-steel p {
    text-align: center;
    font-size: 14px;
  }
}

/* Our Produccts section */

.our-pr {
  padding: 70px 0 20px;
  background: url("../../../../img/our-pro.png");
  background-size: cover;
  background-repeat: no-repeat;

  h2 {
    text-align: center;
    color: #a6221d;
    font-size: 48px;
    font-weight: 600;
  }

  p {
    text-align: center;
    color: #000;
    font-weight: 500;
  }
}

.card-title {
  margin-bottom: 0.5rem;
  text-align: center;

  a {
    color: #000;
    text-decoration: none;
    text-align: center;
  }
}

.our-pro-sec1 {
  padding: 50px 0 50px;
}

.our-pro-sec2 {
  margin-top: 25px;
}

.card0,
.card1,
.card2,
.card3,
.card4,
.card5 {
  border: none;
  position: relative;
  width: 85%;
  margin: 0 auto;
}

.card-body img {
  position: absolute;
  width: 20%;
  opacity: 0.9;
  top: 233px;
  left: 0px;
}

.card-body1 img {
  position: absolute;
  width: 20%;
  opacity: 0.9;
  top: 226px;
  left: -25px;
}

.abt1-steel a:hover {
  color: #000;
}

@media only screen and (max-width: 1024px) {
  

  .our-pro-sec1 {
    padding: 28px 5px 28px;
  }
    .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
      content: 'prev';
      font-size: 30px;
      color: #000;
      font-weight: 900;
  }
    
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
    font-size: 30px;
      color: #000;
      font-weight: 900;
  }
  .card-body img {
    top: 195px;
    left: 0px;
}
.card-body1 img {
  top: 168px;
  left: 0px;
}
}

@media only screen and (max-width: 768px) {
  .our-pr {
    padding: 30px 5px 30px;
  }
  .abt1-steel {
    height: 30rem;
  }
  .card-body {
    flex: 1 1 auto;
    padding: 5px 10px;

    img {
      width: 25%;
      top: 90px;
      left: -19px;
      z-index: 999;
    }
  }
  .foot-log img {
    width: 100%;
}

  .card-body1 img {
    width: 25%;
    top: 90px;
    left: -19px;
    z-index: 999;
  }

  h5.card-title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
  }

  .card-title a {
    font-size: 15px;
}
}

@media only screen and (max-width: 425px) {
  .card0,
  .card1,
  .card2,
  .card3,
  .card4,
  .card5 {
    border: none;
    position: relative;
    width: 60%;
    margin: 0 auto;
  }

  .card-body img, .card-body1 img {
    width: 25%;
    top: 165px!important;
    left: 0px;
    z-index: 999;
}
  .abt1-steel {
    height: 25rem;
}
}

@media only screen and (max-width: 325px) {
  .card-body img,
  .card-body1 img {
    display: none;
  }

  .our-pr h2 {
    font-size: 30px;
  }
}

/* Services section */

.yrs-exp img {
  width: 100%;
}

.cout-up {
  padding: 70px 0 70px;
  background: url("../../../../img/yea-ex.png");
  background-size: cover;
  background-repeat: no-repeat;

  h2 {
    text-align: center;
    color: #fff;
    font-size: 44px;
    font-weight: 900;
  }

  h5 {
    text-align: center;
    color: #f5bd95;
  }
}

.cout1,
.cout2 {
  border-right: 2px solid silver;
}

@media only screen and (max-width: 1024px) {
  .cout-up {
    padding: 20px 0 20px;
  }

  .cout1,
  .cout2,
  .cout3 {
    border-right: 2px solid silver;
    height: 125px;
  }
}

@media only screen and (max-width: 768px) {
  .cout-up {
    padding: 20px 0 20px;
  }

  .cout1,
  .cout2,
  .cout3 {
    border-right: 1px solid silver;
    height: 98px;
    width: 33%;
  }

  .cout-up {
    h2 {
      margin: 0;
      font-size: 20px;
    }

    h5 {
      font-size: 18px;
    }
  }
  .card-body img, .card-body1 img {
    width: 25%;
    top: 130px;
    left: 0px;
    z-index: 999;
}
}

@media only screen and (max-width: 320px) {
  .cout-up {
    h2 {
      margin: 0;
      font-size: 16px;
    }

    h5 {
      font-size: 15px;
    }
  }

  .cout1,
  .cout2,
  .cout3 {
    height: 75px;
    border: unset;
    width: 33%;
  }
}

/* Slider section */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  color: #545454;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}
// end

// banner-section
.banner {
  padding: 50px;
  color: #fff;
  background: url("../../../../img/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: right;
}
.banner-inn h2 {
  color: #000;
  font-size: 38px;
  font-weight: 700;
}

/* slider 2 about section */

.silder2 {
  background: url("../../../../img/slider2.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 30rem;
  position: relative;
}

.pro-sli img {
  width: 70%;
  border: 2px dotted #8b8282;
  border-radius: 10px;
  box-shadow: 0.9px 89.1px 23.3px rgb(0 0 0 / 3%), 3.1px 52.6px 19.9px rgb(0 0 0 / 4%),
    14px 16px 80px rgb(0 0 0 / 7%) inset;
}

.ourpro-img h3 {
  text-align: center;
  font-size: 22px;
  color: #a71e25;
  letter-spacing: 1px;
  margin-top: 27px;
  font-weight: 600;
}

.pro-sli {
  position: absolute;
  top: 95px;
  right: 70px;
}

.pro-sli2 {
  .swiper-button-prev:after {
    content: "prev";
    color: #000;
    font-size: 22px;
  }

  .swiper-button-next:after {
    content: "next";
    color: #000;
    font-size: 22px;
  }
}

@media only screen and (max-width: 768px) {
  .silder2 {
    height: 21rem;
  }

  .ourpro-img h3 {
    text-align: center;
    font-size: 14px;
  }
}

@media only screen and (max-width: 425px) {
  .silder2 {
    background: unset;
  }

  .pro-sli {
    position: absolute;
    top: -35px;
    right: 0px;

    /* height: 10px; */
  }

  .our-pr p {
    text-align: center;
    color: #000;
    font-size: 12px;
    font-weight: 400;
  }
  .footer-sec {
    padding: 10px 30px;
}
.foot-copy {
  text-align: center;
}
.foot-Devlope {
  text-align: center;
}
}


.foot-Devlope a {
  color: #005ea1;
}