.footer {
    padding: 30px 0 20px;
}
.foot-log img {
    width: 70%;
}
.foot-abt p {
    margin-top: 10px;
    text-align: justify;
}
.foot-cont {
    padding: 20px 0 0;
    margin: 0 auto;
}
.foot-cont-head h5 {
    color: #A6221D;
    font-size: 18px;
    font-weight: 600;
}
.footer a {
    text-decoration: none;
    color: #383737;
    font-size: 14px;
}
.foot-cont address p {
    float: right;
    display: contents;
}
.foot-bran-head address p{
    float: right;
    display: contents;
}
.foot-bran-head h5 {
    font-size: 16px;
    color: #A6221D;
    font-weight: 600;
}
.foot-bran {
    padding: 20px 0 0;
}
.foot-copy {
    font-size: 14px;
}
.foot-Devlope {
    text-align: right;
}
.footer hr {
    margin: 10px 0;
}
address {
    margin-bottom: 1rem;
    font-style: normal;
    font-size: 14px;
    line-height: inherit;
}
address {
    margin-bottom: 1rem;
    font-style: normal;
    display: flex;
    font-size: 14px;
    line-height: inherit;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: baseline;
    gap: 8px;
}
.cont-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 7px;
    line-height: 37px;
}
.cont-footer1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    line-height: 37px;
}
@media only screen and (max-width: 768px){
    .footer {
        padding: 30px 0 20px;
        
    }
    .footer-sec {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 420px){
.footer-sec {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}
}