@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
}
/* body{
  overflow: hidden;
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* header */

.navbar-nav {
  float: left;
  margin: 0;
}
.menu ul li {
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  padding: 10px 0px 0px 20px;
  text-transform: uppercase;
}

.menu ul li a {
  text-decoration: none;
  color: #4e4e4e;
}

/* top-text section */

/*  */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 425px){
  .banner-inn h2 {
      color: #fff;
      font-size: 38px;
      font-weight: 700;
      text-align: left;
  }
  .about-section {
    padding: 50px 0px 30px;
    position: relative;
    overflow: hidden;
}
}