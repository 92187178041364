.cont-sec {
    padding: 70px 0 50px 50px;
    background: linear-gradient(90deg, #fff 52%,#b50100 50% 100%);
    color: #fff;
    border-bottom: 1px solid #eee;
}
.cont-sec a {
    text-decoration: none;
    color: #ffffff;
}
.cont-seca input {
    border: unset;
    padding: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.btn-primary {
    color: #fff;
    float: right;
    margin-top: 22px;
    background-color: #A6221D;
    border: unset;
}
.btn-primary:hover{
    background: #A6221D;
    color: #fff;
}
.cont-secb {
    text-align: center;
    padding: 50px 0 50px;
}
.add-1 {
    margin-bottom: 30px;
}
.add-1 h5 {
    color: #dfdfdf;
    font-size: 28px;
    font-weight: 800;
    /* background: linear-gradient(90deg, #fff 52%,#ddd 50% 100%); */
}
.cont-seca label{
    color: #000;
}
.cont-seca form button{
    color: #fff;
    background-color: #b50100;
    border: unset;
}
.cont-secb {
    text-align: center;
    padding: 100px 0 50px;
}
.cont-sec address {
    margin-bottom: 1rem;
    font-style: normal;
    display: flex;
    font-size: 14px;
    line-height: inherit;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: baseline;
    gap: 8px;
}
@media only screen and (max-width:767px) {
    .cont-sec {
        padding: 30px 0 20px 0px;
        background: none; 
    }
}