.con-de1 {
  display: flex;
}
.head-sec2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
}
.head-sec2 ul {
  display: flex;
  padding: 0px;
  margin-top: 5px;
  margin-bottom: 10px;
  gap: 20px;
  list-style: none;
}
.mobile-menu {
  display: none;
}
.con-de1 svg {
  color: #b50100;
  font-weight: 900;
  font-size: 20px;
}
.con-de1 a {
  text-decoration: none;
  color: #383737;
  margin: 0 5px;
}
.head-logo {
  text-align: center;
}
.con-de1 div {
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.nav-top {
  padding: 15px 0 10px;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  float: right;
  list-style: none;
}
nav.nav.navbar-nav a {
  color: #343434;
  text-decoration: none;
  font-size: 20px;
}
.nav-links a.nav-link.active {
  color: #a6221d !important;
}
.nav-top img {
  width: 100%;
}
.navbar-nav {
  float: left;
  margin: 0;
}
.menu ul li {
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  padding: 10px 0px 0px 20px;
  text-transform: uppercase;
}

.menu ul li a {
  text-decoration: none;
  color: #4e4e4e;
}
.na-me {
  display: flex;
}
.na-me {
  display: flex;
  align-content: stretch;
  justify-content: space-between;
}

/* top-text section */

/*  */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
@media only screen and (max-width: 1024px) {
  .con-de1 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  nav.nav.navbar-nav {
    display: none;
  }
  .head-sec2 {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }
  .top-header {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .nav-top img {
    width: 300px;
  }
  .con-de1 div {
    margin-left: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .mobile-menu {
    display: block;
  }
  .nav-links a.nav-link.active {
    color: #fff !important;
  }
  .product-body-sec img {
    position: absolute;
    width: 20%;
    opacity: 0.9;
    top: unset !important;
    bottom: 40px !important;
    left: 0px;
  }
}
@media only screen and (max-width: 425px) {
  .con-de1 {
    font-size: 14px;
    width: 71%;
  }
  #prod-img-2 {
    width: 100%;
}
}
@media only screen and (max-width: 375px) {
  .con-de1 {
    display: flex;
    font-size: 14px;
    width: 100%;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
}

/* toggle */
