:root {
  --primaryColor: #bb0200;
  --secondary-color: #fff;
}
// --------------------------- Mobile menu --------------------------
.menu-open-button {
  position: relative;
}
.menu-open-button span {
  background: #000;
  border-radius: 5px;
  display: block;
  height: 5px;
  margin-top: 1px;
  transition: 0.4s;
  width: 33px;
}
  .menu-open-button {
    position: relative;
    span {
      width: 32px;
    height: 4px;
    background: var(--primaryColor);
    display: block;
    border-radius: 5px;
    transition: 0.4s;
      &:nth-child(1) {
        transform: translateY(-5px);
      }
      &:nth-child(2) {
        transform: scale(1);
        transition-delay: 0;
      }
      &:nth-child(3) {
        transform: translateY(5px);
      }
    }
  }
  .menu-open-button.active {
    span {
      &:nth-child(1) {
        transform: translate(0px);
        transform: rotate(90);
        opacity: 0;
      }
      &:nth-child(2) {
        transform: scale(0);
        // opacity: 0;
        transition-delay: 0.4s;
      }
      &:nth-child(3) {
        transform: translate(0px);
        transform: rotate(180);
        opacity: 0;
      }
    }
  }
// }
.toggle-menu {
  background: var(--secondaryColor);
  position: absolute;
  top: 0;
  right: -50rem;
  width: 100%;
  // height: 300vh;
  transition: 0.6s;
  transition-delay: 0.5s;

  .menu-close-button {
    position: absolute;
    top: 10px;
    padding: 0rem 3rem;
    right: 5px;
    transition: 0.6s;
    background: #bb0200;
    color: #fff;
    z-index: 999;
    span {
      color: var(--secondary-color);
    font-size: 35px;
    /* float: right; */
    position: absolute;
    right: 15px;
    }
  }
  .menu-close-button.active {
    right: -200px;
  }
  ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;

    li.main-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      a.main-nav-link {
        text-decoration: none;
        color: #fff;
        font-size: 20px;
      }
      ul {
        display: none;
      }
      a.nav-link.active {
        color: #fff !important;
      }
    }
  }
}
.toggle-menu.active {
  right: 0;
}
nav {
  .header-bar {
    padding: 0 50px;
  }
  .logo {
    img {
      width: 230px;
    }
  }
}

// -----------------animated toggle bar---------------//

// ------------end----------------//
@media only screen and (max-width: 768px) {
  a.nav-link {
    color: #fff;
    letter-spacing: 1px;
  }
  .toggle-menu .menu-close-button {
    position: fixed;
    top: 0px;
    // width: 80%;
    right: 0;
    height: 100vh;
    transition: 0.6s;
    background: #2a2a2afa;
    color: #fff;
    z-index: 999;
  }
  .menu-close-button {
    padding: 0rem 10rem !important;
  }
  .toggle-menu .menu-close-button.active {
    right: -500px;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
  font-size: 24px;
}
}
@media only screen and (max-width: 425px) {
  .menu-close-button {
    padding: 0rem 3rem !important;
  }
  .silder2 .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
    font-size: 20px!important;
  }
  .silder2 .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
    font-size: 20px !important;
  }
  .silder .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
    font-size: 20px!important;
  }
  .silder .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
    font-size: 20px !important;
  }
  .cont-sec {
    padding: 70px 0 50px 50px;
    background: unset!important;
    color: #000;
    border-bottom: 1px solid #eee;
}
.cont-sec address{
  color: #000!important;
}
.cont-sec a {
  color: #525252 !important;
  font-size: 13px;
}
.add-1 h5{
  color: #bb0200!important;
}
}
@media only screen and (max-width: 375px) {
  .menu-open-button {
    right: 36px;
}
  .toggle-menu .menu-close-button {
    position: fixed;
    top: 0px;
    width: 80%;
    right: 0px;
    height: 100vh;
    transition: 0.6s;
    background: rgba(42, 42, 42, 0.9803921569);
    color: #fff;
    z-index: 999;
}
}
